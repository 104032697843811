import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Vue from 'vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/images'
  },
  {
    path: '/images',
    name: 'images',
    meta: { name: 'Compressor de imagens' },
    component: HomeView
  },
  {
    path: '/pdf',
    name: 'pdf',
    meta: { name: 'Compressor de PDF' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = (to.meta.name as string) || 'Compressor de imagens' + " - Edutecka";
  next();
});

// const DEFAULT_TITLE = 'Compressor de imagens';
// router.afterEach((to, from) => {
//     // Use next tick to handle router history correctly
//     // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
//     Vue.nextTick(() => {
//         document.title = (to.meta.title as string) || DEFAULT_TITLE;
//     });
// });
export default router
