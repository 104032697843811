<template>
  <div
    class="flex flex-col items-center justify-center w-full max-w-4xl gap-8 p-4 mx-auto"
  >
    <div class="flex gap-2 px-4 py-2 rounded-full bg-zinc-200">
      <svg
        width="30px"
        height="30px"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.91962 8.7141C8.49508 7.38938 9.47567 6 10.8668 6H37.1332C38.5243 6 39.5049 7.38938 39.0804 8.7141C37.8993 12.3995 36 19.0894 36 24C36 28.9106 37.8993 35.6005 39.0804 39.2859C39.5049 40.6106 38.5243 42 37.1332 42H10.8668C9.47567 42 8.49508 40.6106 8.91962 39.2859C10.1007 35.6005 12 28.9106 12 24C12 19.0894 10.1007 12.3995 8.91962 8.7141Z"
          stroke="#000000"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4 15C5.5 17 5.99988 21 5.99988 24C5.99988 27 5.5 31 4 33"
          stroke="#000000"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18 15.5H30"
          stroke="#000000"
          stroke-width="4"
          stroke-linecap="round"
        />
        <path
          d="M18 24H30"
          stroke="#000000"
          stroke-width="4"
          stroke-linecap="round"
        />
        <path
          d="M18 32H22"
          stroke="#000000"
          stroke-width="4"
          stroke-linecap="round"
        />
        <path
          d="M44 15C42.5 17 42.0001 21 42.0001 24C42.0001 27 42.5 30 44 33"
          stroke="#000000"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <h1 class="text-2xl font-semibold">Comprimir imagens</h1>
    </div>
    <!-- Área de drag and drop para seleção de arquivo -->
    <div
      @dragenter.prevent="toggleActive()"
      @dragleave.prevent="toggleActive()"
      @dragover.prevent
      @drop.prevent="drop"
      :class="{
        'bg-green-100 border-green-300 dark:bg-green-100': dragActive,
        'border-zinc-300': !dragActive,
      }"
      class="flex flex-col items-center justify-center w-full pt-12 pb-8 mb-4 border-4 border-dashed rounded"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-20 h-20 animate-bounce"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
        />
      </svg>
      <div class="flex flex-col items-center justify-center mt-4 text-zinc-500">
        <p>Arraste e solte sua imagem aqui</p>
        <p class="text-sm">ou</p>
        <button
          @click="triggerFileInput"
          class="flex flex-row items-center gap-4 px-4 py-2 mt-2 font-semibold text-white bg-blue-600 rounded"
        >
          <i class="ri-upload-cloud-2-line ri-lg"></i>
          <span>Escolha arquivos...</span>
        </button>
        <input
          type="file"
          ref="fileInput"
          @change="selectFile"
          class="hidden"
          accept="image/*"
          multiple
        />
      </div>
    </div>

    <template v-if="originalFiles.length > 0">
      <!-- Grid com preview de imagens com largura 80px -->
      <p ref="original" class="text-base font-semibold">Imagens Originais</p>
      <div class="grid w-full gap-4 mb-4 md:grid-cols-3">
        <div v-for="(file, index) in originalFiles" :key="index">
          <div
            class="overflow-hidden border rounded-xl"
            :class="{
              'bg-zinc-100': file.converting,
              'border-red-500': file.error,
            }"
          >
            <div class="flex flex-row items-center gap-4 p-2">
              <img
                :src="file.preview"
                alt="Preview"
                class="object-cover w-20 h-auto"
              />
              <div
                class="flex flex-col items-start justify-start !text-left gap-4"
              >
                <span class="text-xs font-semibold line-clamp-3">{{
                  file.name
                }}</span>
                <div class="flex gap-2">
                  <span
                    class="px-1 text-xs font-semibold bg-gray-200 rounded-full"
                  >
                    {{ getSizeFormatted(file.size) }}
                  </span>
                  <button
                    @click="originalFiles.splice(index, 1)"
                    :disabled="processing"
                    :class="{
                      'cursor-not-allowed opacity-40': processing,
                      'cursor-pointer': !processing,
                    }"
                    class="px-1 text-xs font-semibold text-red-500 bg-red-200 rounded-full"
                  >
                    remover
                  </button>
                </div>
              </div>
            </div>
            <div v-if="file.converting" class="w-full">
              <!-- progress bar -->
              <div class="block w-full h-2 bg-blue-100 rounded-full">
                <div
                  class="block h-2 bg-blue-500 rounded-full"
                  :style="{ width: file.progress * 100 + '%' }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Seleção de tamanho máximo e botão de conversão -->
      <div 
        class="flex flex-row items-end justify-between w-full gap-4 p-4 mb-4 border rounded-xl"
      >
        <div class="flex flex-col gap-2">
          <label for="max-size" class="text-sm font-semibold"
            >Tamanho máximo (MB):</label
          >
          <select id="max-size" v-model="maxSize" class="p-2 border rounded">
            <option value="0.1">128 KB</option>
            <option value="0.2">256 KB</option>
            <option value="0.5">512 KB</option>
            <option value="1">1 MB</option>
            <option value="2">2 MB</option>
            <option value="5">5 MB</option>
            <option value="10">10 MB</option>
          </select>
        </div>
        <div class="">
          <button
            @click="convertFiles"
            class="flex flex-row items-center gap-4 px-4 py-2 font-semibold text-white bg-blue-600 rounded"
            :disabled="processing"
            :class="{
              'cursor-not-allowed opacity-40': processing,
              'cursor-pointer': !processing,
            }"
          >
            <template v-if="processing">
              <span>Processando...</span>
              <i class="ri-loader-3-line ri-lg animate-spin"></i>
            </template>
            <template v-else>
              <span>Comprimir</span>
              <i class="ri-collapse-diagonal-2-line ri-lg"></i>
            </template>
          </button>
        </div>
      </div>
    </template>

    <div v-show="convertedImages.length > 0">
      <!-- Grid com preview de imagens convertidas com largura 280px -->
      <div class="flex flex-wrap justify-between w-full mb-4">
        <p ref="converted" class="mb-4 text-base font-semibold">
        Imagens Convertidas
      </p>

      <div class="">
        <button @click="downloadAll" :disabled="processing" class="flex flex-row items-center gap-2 px-4 py-2 mt-2 text-white bg-green-600 rounded">
          <span>Download All</span>
          <i class="ri-download-line ri-lg"></i>
        </button>
      </div>
    </div>
      <div class="grid gap-4 md:grid-cols-3 xl:grid-cols-4">
        <div
          v-for="(convertedImage, index) in convertedImages"
          :key="index"
          class="flex flex-col items-center justify-between gap-4 p-4 overflow-hidden bg-white border rounded-xl"
        >
        <div class="flex flex-col items-center justify-between gap-4">
          <img
            :src="convertedImage.src"
            alt="Preview"
            class="h-auto w-72"
          />
          <span class="text-xs">{{ convertedImage.name }}</span>
          <div class="flex flex-row items-center">
            <span class="text-xs">{{
              getSizeFormatted(convertedImage.originalFileSize)
            }}</span> 
            <i class="ri-arrow-right-line"></i>
            <span class="text-xs font-semibold">{{
              getSizeFormatted(convertedImage.size)
            }}</span>
          </div>
        </div>
          
          <a
            :href="convertedImage.src"
            :download="'compressed-' + convertedImage.name"
            class="flex flex-row items-center gap-2 px-4 py-2 mt-2 text-white bg-green-600 rounded"
          >
            <span>Download</span>
            <i class="ri-download-line"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import imageCompression from "browser-image-compression";
import { useToast } from "vue-toastification";
export default {
  data() {
    return {
      maxSize: "2", // Tamanho máximo padrão (em MB)
      originalFiles: [], // Armazena os arquivos originais
      convertedImages: [], // Armazena as imagens convertidas
      dragActive: false, // Controle de estado do drag and drop
      processing: false, // Controle de estado do processamento
    };
  },
  methods: {
    toggleActive() {
      this.dragActive = !this.dragActive;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async selectFile(event) {
      const files = Array.from(event.target.files);
      // this.originalFiles = [];
      
      for (const file of files) {
        if (file.type.startsWith("image/")) {
          const preview = URL.createObjectURL(file);
          this.originalFiles.push({
            name: file.name,
            size: file.size,
            preview,
            converting: false,
            error: false,
            progress: 0,
          });
        } else {
          console.warn(
            `O arquivo ${file.name} não é uma imagem e foi ignorado.`
          );

          this.toast.error(
            `O arquivo ${file.name} não é uma imagem e foi ignorado.`
          );
        }
      }
      try {
        this.$refs.original.scrollIntoView({
        behavior: "smooth",
      });
      } catch (error) {
        console.error("Erro ao rolar para o elemento:", error);
      }
    },
    async convertFiles() {
      this.processing = true;
      this.convertedImages = [];
      const maxSizeBytes = this.maxSize * 1024 * 1024; // Convertendo MB para bytes

      for (const file of this.originalFiles) {
        file.converting = true;
        const imageFile = await fetch(file.preview).then((res) => res.blob());
        const options = {
          maxSizeMB: this.maxSize,
          useWebWorker: true,

          onProgress: (progress) => {
            file.progress = progress;
          },
        };

        try {
          const compressedFile = await imageCompression(imageFile, options);
          const compressedPreview = URL.createObjectURL(compressedFile);
          this.convertedImages.push({
            src: compressedPreview,
            name: file.name,
            size: compressedFile.size,
            originalFileSize: file.size,
          });
          this.toast.success(`Imagem ${file.name} processada com sucesso!`);
        } catch (error) {
          console.error("Erro na compressão da imagem:", error);
          this.toast.error("Erro na compressão da imagem: " + file.name);
          file.error = true;
        } finally {
          file.converting = false;
        }
      }
      this.processing = false;
      try {
        this.$refs.converted.scrollIntoView({
        behavior: "smooth",
      });
      } catch (error) {
        console.error("Erro ao rolar para o elemento:", error);
      }
      
    },
    drop(event) {
      this.toggleActive();
      const files = Array.from(event.dataTransfer.files);
      // this.originalFiles = [];

      for (const file of files) {
        const preview = URL.createObjectURL(file);
        this.originalFiles.push({ name: file.name, size: file.size, preview });
      }
    },
    getSizeFormatted(size) {
      if (size < 1024) {
        return size + " B";
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + " KB";
      } else {
        return (size / (1024 * 1024)).toFixed(2) + " MB";
      }
    },
    downloadAll() {
      this.convertedImages.forEach((image) => {
        const link = document.createElement('a');
        link.href = image.src;
        link.download = image.name;
        link.click();
      });
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
};
</script>

<style scoped>
.border {
  border-width: 2px;
}
</style>
